export default {
  created () {
    this.onCreated()
  },
  destroyed () {
    this.onDestroyed()
  },
  methods: {
    /**
     * Life cycle
     */
    onCreated () {
      this.createListeners()
      this.scrollTop()
    },
    onDestroyed () {
      this.removeListeners()
    },
    /**
     * Listeners
     */
    createListeners () {
      window.addEventListener('scroll', this.onScroll)
    },
    removeListeners () {
      window.removeEventListener('scroll', this.onScroll)
    },
    /**
     * Methods
     */
    scrollTop () {
      const scrollPosition = localStorage.getItem('scrollPosition')
      if (scrollPosition) {
        return
      }
      setTimeout(() => {
        window.scroll({
          top: 0,
          behavior: 'smooth'
        })
      })
    },
    onScroll () {
      const scrollMaxY = window.scrollMaxY || Math.round(document.documentElement.scrollHeight - document.documentElement.clientHeight)

      if (
        this.loaders.newPage ||
        scrollMaxY < 100 ||
        this.pagination.page >= this.pagination.pages
      ) {
        return
      }

      if ((window.scrollY + (window.innerHeight / 2)) > scrollMaxY) {
        this.handleSetPagination()
        this.handleGetData()
      }
    },
    /**
     * Parent methods
     */
    handleGetData () {
      /**
       * Get component data.
       * This one should be overridden from the parent component
       */
    },
    handleSetPagination () {
      /**
       * Set component data pagination.
       * This one should be overridden from the parent component
       */
    }
  }
}
