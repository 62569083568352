<template>
  <FilterContainer
    :lang="$store.getters.language.lang"
    :auto-focus="true"
    @filterBody="handleFilterByName($event)"
    @close="goBack"
  >
    <template v-slot:content>
      <div class="segment">
        <div class="segment-content">

          <!-- loader -->
          <template v-if="loading">
            <Skeleton
              class="margin-bottom"
              width="100%"
              height="21px"
              borderRadius="16px"
            />

            <Skeleton
              class="margin-bottom-sm"
              width="100%"
              height="268px"
              borderRadius="16px"
              v-for="index in 3"
              :key="index"
            />
          </template>

          <template v-else-if="results.length">
            <div class="heading-small margin-bottom" v-text="resultsCount" />
            <SpecialistCard
              v-for="(specialist, key) in results"
              :key="key"
              :specialist="specialist"
            />

            <LoaderSimple class="margin-y" v-if="loaders.newPage"/>
          </template>

          <DataZeroSimple
            v-else
            :text="dataZeroText"
            :image="require('@/assets/icons/search/search-specialists.svg')"
          />
        </div>
      </div>
    </template>
  </FilterContainer>
</template>

<script>
import {
  FilterContainer,
  LoaderSimple,
  Skeleton
} from '@seliaco/red-panda'
import SpecialistCard from '@/components/cards/specialist-card/SpecialistCardWrapper.vue'
import SpecialistsListMixinV2 from '@/mixins/specialists-list-mixin-v2'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'
import { mapGetters } from 'vuex'

export default {
  name: 'SpecialistsSearch',
  components: {
    FilterContainer,
    SpecialistCard,
    DataZeroSimple,
    LoaderSimple,
    Skeleton
  },
  mixins: [SpecialistsListMixinV2],
  data () {
    return {
      searchValue: ''
    }
  },
  created () {
    this.onCreated()
  },
  destroyed () {
    this.onDestroyed()
    this.$store.dispatch('specialistByNameStore/cleanResults')
  },
  methods: {
    goBack () {
      if (this.$route.query.back) {
        this.$router.push({
          path: this.$route.query.back
        })
      } else {
        this.$router.go(-1)
      }
    },
    handleFilterByName (field) {
      this.searchValue = field.value

      if (
        !field ||
        !field.value ||
        field.value.length === 0 ||
        field.value.match(/^ *$/) !== null
      ) {
        return
      }

      this.$store.commit('specialistByNameStore/SET_LOADING', {
        key: 'results',
        value: true
      })
      this.$store.dispatch(
        'specialistByNameStore/setQuery',
        {
          values: {
            name: field.value
          },
          cleanedRequest: true
        }
      )
      this.scrollTop()
    },
    /**
     * Overrides
     */
    handleGetData () {
      this.$store.dispatch(
        'specialistByNameStore/getResults',
        { cleanedRequest: false }
      )
    },
    handleSetPagination () {
      this.$store.commit('specialistByNameStore/SET_PAGINATION', {
        page: this.pagination.page + 1
      })

      this.$store.commit('specialistByNameStore/SET_LOADERS', {
        key: 'newPage',
        value: true
      })
    }
  },
  computed: {
    ...mapGetters({
      results: 'specialistByNameStore/results',
      pagination: 'specialistByNameStore/pagination',
      loading: 'specialistByNameStore/loading',
      loaders: 'specialistByNameStore/loaders'
    }),
    resultsCount () {
      if (this.loading) {
        return null
      }

      return this.pagination.filtered
    },
    dataZeroText () {
      if (this.searchValue?.length > 0) {
        return this.$translations.search['filter-by'].datazero.replaceAll(
          '{name}',
          this.searchValue
        )
      }

      return this.$translations.search['filter-by'].name
    }
  }
}
</script>

<style lang="sass" scoped>
::v-deep
  .search-bar-field_input
    border-radius: 8px !important
  .search-bar-field_input-default
    border-radius: 8px !important
</style>
